<template>
  <div>
    <ConfirmModal ref="DeleteConfirmModal" @ok="onConfirmed" content="This will be removed. Continue?" />
    <DeviceView v-if="is_view"
      :device="selected_device"
      @back="onBack"
      @payload="onEditPayload">
    </DeviceView>
    <Search @search="onSearch" v-else>
      <DeviceForm title="Add Device" ref="AddModal" @payload="onAddPayload" />
      <BulkUploadResult ref="BulkResultModal" title="Result" :result="bulkUploadContext" />
      <ConfirmModal ref="ConfirmModal" :content="`${bulkUploadContext.items.length} items will be uploaded. Continue?`" @ok="bulkSubmit" />
      <ProgressModal ref="ProgressModal" :counter="UploadProgressCounter" @cancel="onStopBulkUpload" />
      <CRow>
        <CCol sm="12">
          <CTableWrapper
            :items="list"
            :fields="FIELDS"
            header-icon="cil-storage"
            :add-btn="true"
            :count="count"
            caption="AI Bridge"
            hover
            small
            fixed
            :checkable="false"
            @import="onImport"
            @import-example="onImportExample"
            @add="onAdd"
            @view="onView"
            @delete="onDelete"
            @delete-checked-items="onDeleteCheckedItems"
          />
        </CCol>
      </CRow>
      <CRow class="justify-content-end" v-if="pageTotal > 1">
        <CPagination
          :activePage.sync="pageCurrent"
          :pages="pageTotal"
          @update:activePage="onUpdatePage"
        >
        </CPagination>
        <CInput type="number" v-model="pageDirectSet" @change="onChangePageDirectSet" class="px-3 w-auto" style="max-width: 120px;" />
      </CRow>
      <input type="file" id="_upload_file" hidden accept=".csv" @change="onFileChange"/>
    </Search>
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import utility from '@/utility'
import Search from '@/containers/Search.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import ProgressModal from '@/components/modals/ProgressModal.vue'
import BulkUploadResult from '@/components/modals/BulkResultModal.vue'
import CTableWrapper from '@/components/EdxTable'
import DeviceView from './DeviceView.vue'
import DeviceForm from './DeviceForm.vue'
import { mapState } from 'vuex'

export default {
  name: 'Devices',
  components: {
    Search,
    CTableWrapper,
    BulkUploadResult,
    ConfirmModal,
    ProgressModal,
    DeviceView,
    DeviceForm,
  },
  data () {
    return {
      FIELDS: [
        // {key: 'checkbox', label: '', _style:'width:50px;'},
        {key: 'organization_name', label: 'Organization', _classes: 'align-middle'},
        {key: 'name', label: 'Name', _classes: 'align-middle'},
        {key: 'mac', label: 'MAC', _classes: 'align-middle'},
        {key: 'model', label: 'Model', _classes: 'align-middle'},
        {key: 'max_ch', label: 'Ch', _classes: 'align-middle'},
        'status',
        {key: 'ops', label: 'Operations', _style: 'width: 100px;'}
      ],
      querying: false,
      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      count: 0,
      searchText: '',
      list: [],

      bulkUploadContext: {
        header: [],
        items: [],
        current: 0,
        maxPage: 0,
        okCnt: 0,
        errors: []
      },

      confirm_show: false,

      is_view : false,
      selected_device : null
    }
  },
  mounted() {
    this.getList();
  },
  computed: {
    ...mapState([
      'capability'
    ]),
    UploadProgressCounter() {
      return parseInt((Math.min(this.bulkUploadContext.current, this.bulkUploadContext.maxPage) / Math.max(this.bulkUploadContext.maxPage, 1)) * 100);
    }
  },
  methods: {
    getList: function() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        search: this.searchText,
        page: this.pageCurrent
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/install/devices/${query_string}`)
        .then(result => {
          this.querying = false;
          
          for(let el of result.data.list){
            el['mac'] = this.attachMacColon(el['mac']);
          }
          this.list = result.data.list;
          this.pageTotal = result.data.page.total;
          this.pageCurrent = result.data.page.current;
          this.count = result.data.page.count;
        })
        .catch(e => {
          console.error(e);
          this.querying = false;
        })
    },
    addBulk: function() {
      if (this.bulkUploadContext.current > this.bulkUploadContext.maxPage) {
        this.$refs.ProgressModal.close();
        this.$refs.BulkResultModal.open();
        this.getList();
        // console.log(this.bulkUploadContext.errors);
        return;
      }
      // 현재 페이지의 N개 ($resource.BULK_UPLOAD_CNT) 를 잘라 targetItems로 만든다.
      let targetItems = this.bulkUploadContext.items.slice(
        this.$resource.BULK_UPLOAD_CNT*this.bulkUploadContext.current,
        Math.min(this.$resource.BULK_UPLOAD_CNT*(this.bulkUploadContext.current+1), this.bulkUploadContext.items.length)
      );

      axios.post(`/api/install/upload/devices/`, {
        page_total: this.bulkUploadContext.maxPage,
        page_current: this.bulkUploadContext.current,
        items: targetItems,
        headers: this.bulkUploadContext.header
      })
        .then(result => {
          // console.log(result.data);
          for (result of result.data.result) {
            if (!result.result) {
              this.bulkUploadContext.errors.push(result);
            } else {
              this.bulkUploadContext.okCnt++;
            }
          }
          this.bulkUploadContext.current++;
          this.addBulk();
        })
        .catch(e => {
          console.error(error);
        })

      // this.$api.AddDevices((result) => {
      //   // console.log(result.data);
      //   for (result of result.data.result) {
      //     if (!result.result) {
      //       this.bulkUploadContext.errors.push(result);
      //     } else {
      //       this.bulkUploadContext.okCnt++;
      //     }
      //   }
      //   this.bulkUploadContext.current++;
      //   this.addBulk();
      // }, (error) => {
      //   console.error(error);
      //   // TODO. 에러 메시지 표시
      // }, {
      //   page_total: this.bulkUploadContext.maxPage,
      //   page_current: this.bulkUploadContext.current,
      //   items: targetItems,
      //   headers: this.bulkUploadContext.header
      // });
    },
    onSearch: function(text) {
      this.searchText = text;
      this.getList();
    },
    onImport: function() {
      document.getElementById('_upload_file').value=null;
      document.getElementById('_upload_file').click();
    },
    onImportExample : function(){
      const sample_format = [{
        "name":"sample_device-1",
        "mac":"11:22:33:44:55:66",
        "auth_id":"ADMIN",
        "auth_pw":"1234",
        "organization_name":"Super Smart Organization"
      }]
      let filename = "devices_sample.csv"
      utility.DownloadCSV(sample_format,filename);
    },
    onAdd: function() {
      this.$refs.AddModal.open({
        device: null,
        title: 'Add Device'
      });
    },
    onAddPayload: function(payload) {
      axios.post(`/api/install/devices/`, payload)
        .then(result => {
          this.getList();
        })
        .catch(error => {
          console.error(error);
          this.$notify.error({
            title: '오류',
            message: `항목을 추가할 수 없습니다: ${error.response.data.error}`,
            offset: 30
          });
        })
    },
    onEditPayload: function(payload) {
      axios.put(`/api/install/devices/${payload.id}/`, payload)
        .then(result => {
          this.getList();
          this.is_view = false;
          this.$notify.success({
            title: '완료',
            message: `항목이 변경되었습니다`,
            offset: 30
          });
        })
        .catch(error => {
          console.error(error);
          this.$notify.error({
            title: '오류',
            message: `항목을 변경할 수 없습니다: ${error.response.data.error}`,
            offset: 30
          });
        })
    },
    onFileChange: function(e) {
      const files = e.target.files;
      const reader = new FileReader;
      if (files.length !== 1) return;
      reader.onload = event => {
        let payload = event.target.result;
        payload = payload.replace(/[\r\n]+$/, '');
        payload = utility.CSVToArray(payload);

        // Context init & cascaded function call
        this.bulkUploadContext.maxPage = parseInt((payload.length-1) / this.$resource.BULK_UPLOAD_CNT);
        this.bulkUploadContext.current = 0;
        this.bulkUploadContext.header = payload[0];
        this.bulkUploadContext.items = payload.slice(1);
        this.bulkUploadContext.okCnt = 0;
        this.bulkUploadContext.errors = [];
        this.$refs.ConfirmModal.open();
      }
      reader.readAsText(files[0]);
    },
    onUpdatePage: function(e) {
      this.pageDirectSet = ""+this.pageCurrent;
      this.getList();
    },
    onChangePageDirectSet: function() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";
      else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = ""+this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getList();
    },
    bulkSubmit: function() {
      this.$refs.ProgressModal.open();
      this.addBulk();
    },
    onStopBulkUpload: function() {
      this.bulkUploadContext.current = this.bulkUploadContext.maxPage + 1;
      this.$refs.ProgressModal.close();
    },

    onView: function(id, order, device) {
      this.selected_device = device;
      this.is_view = true;
    },
    onDelete: function(id) {
      this.$refs.DeleteConfirmModal.open(id);
    },
    onConfirmed: function(payload) {
      axios.delete(`/api/install/devices/${payload}/`)
        .then(result => {
          this.getList();
          this.$notify.success({
            title: '완료',
            message: `항목이 삭제되었습니다`,
            offset: 30
          });
        })
        .catch(e => {
          console.error(e);
          this.$notify.error({
            title: '오류',
            message: `항목을 삭제할 수 없습니다: ${error.response.data.error}`,
            offset: 30
          });
        });
    },
    onDeleteCheckedItems : function(delete_items){
      const fd = new FormData;
      let id_list = delete_items.map((item)=>{
        return item.id;
      })
      fd.append("id_list", JSON.stringify(id_list))
      axios.delete(`/api/install/devices/`, {data:fd})
        .then(result => {
          this.getList();
          this.$notify.success({
            title: '완료',
            message: `항목이 삭제되었습니다`,
            offset: 30
          });
        })
        .catch(e => {
          console.error(e);
          this.$notify.error({
            title: '오류',
            message: `항목을 삭제할 수 없습니다: ${error.response.data.error}`,
            offset: 30
          });
        });
    },

    extractDeviceById: function(id){
      for(let i=0; i<this.list.length; i++){
        if(this.list[i].id===id){
          return this.list[i];
        }
      }
      console.log("list has not id("+id+")");
      return null;
    },
    onBack() {
      this.is_view=false;
    },
    attachMacColon : function(mac){
      if(mac===null){
        return ""
      }
      let result = mac.replace(/(.{2})/g,"$1:")
      result = result.slice(0,-1);
      return result;
    }
  }
}
</script>

<template>
  <div>
    <DeviceForm ref="EditModal" @payload="onPayload" />
    <CCard>
      <CCardHeader>
        <slot name="header">
          <CRow class="justify-content-between">
            <el-button class="ml-2" type="text" icon="el-icon-arrow-left" @click="$emit('back')">Back</el-button>
            <span class="my-2">
              <strong class="h5">{{device.name}}</strong>
            </span>
            <el-button class="mr-2" type="primary" icon="el-icon-edit-outline" @click="onEdit" />
          </CRow>
        </slot>
      </CCardHeader>

      <CCardBody>
        <div class="d-flex flex-wrap justify-content-left">
          <div class="mr-1">
            <!-- <CDropdown color="secondary" :toggler-text="channel_toggler" :flip="true">
              <CDropdownItem v-for="(item, index) in playlist" :key="`device-channel-key-${index}`" @click="onChannel(item.id)">
                {{ index > 0 ? `CH ${index}`:`Multiview` }}
              </CDropdownItem>
            </CDropdown>
            <VideoWebRTCPlayer :url="url" :request_id="request_id" @playlist="onPlaylist" /> -->
            <CDropdown color="secondary" :toggler-text="channel_toggler" :flip="true">
              <CDropdownItem v-for="(item, index) in $utility.Range(0, 9)" :key="`device-channel-${index}`" @click="onChannel(index)">
                {{ index > 0 ? $t('cloud.title.channel_ch', {ch:index}):$t('cloud.title.channel_multiview') }}
              </CDropdownItem>
            </CDropdown>
            <VideoProxyPlayer :url="url" :ch="ch" :username="device.auth_id" :password="device.auth_pw" />
          </div>
          <div>
            <br />
            <CCallout>
              <small class="text-muted">Organization</small>
              <p class="h6 mb-3">{{device.organization_name}}</p>
              <small class="text-muted">Status</small>
              <p class="h6 mb-3"><CBadge :color="badge_color">{{device.status}}</CBadge></p>
              <!-- <strong class="h5">{{device.status}}</strong><br><br> -->
              <small class="text-muted">Model</small>
              <p class="h6 mb-3">{{device.model}}</p>
              <small class="text-muted">MAC</small>
              <p class="h6 mb-3">{{device.mac}}</p>
            </CCallout>
          </div>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import axios from 'axios';
import VideoWebRTCPlayer from "@/components/VideoWebRTCPlayer.vue";
import VideoProxyPlayer from "@/components/VideoProxyPlayer.vue";
import DeviceForm from './DeviceForm.vue'

export default {
  components: {
    DeviceForm,
    VideoWebRTCPlayer,
    VideoProxyPlayer
  },
  props : {
    device : {type:Object, default:()=>{return {}}},
  },
  data(){
    return {
      playlist: [],
      ch: null,
      url: null,
      request_id: null
    }
  },
  mounted: function() {
    console.log(this.device);
    this.onDevice();
  },
  computed: {
    channel_toggler : function(){
      if(!this.ch)
        return this.$t('cloud.title.channel_multiview');
      else
        return this.$t('cloud.title.channel_ch', {ch:this.ch});
    },
    badge_color : function(){
      if(this.device.status==="Active")
        return "success"
      if(this.device.status==="Disconnected")
        return "danger"
      if(this.device.status==="AuthFailed")
        return "waring"
    }
  },
  methods: {
    onDevice() {
      // axios.get(`/api/devices/${this.device.id}/webrtc/path/`)
      //   .then(result => {
      //     this.url = result.data.url;
      //     this.ch = null;
      //   })
      //   .catch(e => {
      //     console.error(e);
      //     this.url = null;
      //     this.ch = null;
      //   });
      axios.get(`/api/devices/${this.device.id}/stream/`)
        .then(result => {
          this.url = result.data.url;
        })
    },
    onEdit(){
      this.$refs.EditModal.open({
        title: 'Edit Device',
        device: this.device
      });
    },
    onChannel(id) {
      // this.ch = this.playlist.findIndex(el => el.id === id)
      // this.request_id = id;
      this.ch = id;
    },
    onPayload(payload) {
      this.$emit('payload', payload);
    },
    onPlaylist(list) {
      this.playlist = list;
      this.ch = null;
      this.target = null;
    }
  }
}
</script>
